@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&display=swap");

.Home_container__bCOhY {
  height: "100vh";
  width: "100vw";
  margin-top: 0px;
}

@media (min-width: 600px) {
  .Home_container__bCOhY {
    margin-left: 40px;
  }
}

@media (min-width: 200px) {
  .Home_container__bCOhY {
    margin-top: 20px;
  }
}

.Home_title__T09hD {
  padding-left: 20px;
  padding-top: 20px;
}

.Home_selectList__iNz2Q {
  padding-left: 20px;
  padding-top: 20px;
}

.Home_logo__27_tb {
  margin-left: auto;
  padding: 8px;
}

.Home_back__93Alp {
  margin-right: auto;
}

.Home_menu__2ncGe {
  margin-right: -16px;
}

.Home_authToolbar__s9DfF {
  height: 7rem;
  display: flex;
  justify-content: "center";
}

.Home_homeImage__2K49M {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Home_mapLegend__aurE5 {
  margin-top: 5px;
  margin-bottom: 5px;
  border: "1px solid #EDEDED";
  background: white;
  padding: 8px 8px;
  border-radius: 5px;
  font: 14px/16px Arial, Helvetica, sans-serif;
}

/* Estilos básicos para los botones */
.Home_mapSelectedVista__2IGJt {
  height: 34px !important;
  padding: 0 32px !important;
  border: 1px solid #A8A6A6CC !important;
  color: #EDEDED !important;
  background-color: #A8A6A6CC !important;
  border-radius: 5px !important;
  font: 14px/16px Arial, Helvetica, sans-serif;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s !important;

}

/* Media query para pantallas pequeñas (móviles) */
@media (max-width: 600px) {
  .Home_mapSelectedVista__2IGJt {
    height: 28px !important;
    /* Cambiar altura */
    padding: 0 20px !important;
    /* Cambiar padding */
    font-size: 12px !important;
    /* Cambiar tamaño de fuente */
  }
}

/* Media query para pantallas medianas (tablets) */
@media (max-width: 900px) {
  .Home_mapSelectedVista__2IGJt {
    height: 30px !important;
    /* Ajuste de altura */
    padding: 0 24px !important;
    /* Ajuste de padding */
    font-size: 13px !important;
    /* Ajuste de fuente */
  }
}

/* Media query para pantallas grandes (escritorios) */
@media (min-width: 901px) {
  .Home_mapSelectedVista__2IGJt {
    height: 34px !important;
    /* Tamaño normal en pantallas grandes */
    padding: 0 32px !important;
    /* Padding normal */
    font-size: 14px !important;
    /* Tamaño de fuente normal */
  }
}

/* Estilo para el estado hover */
.Home_mapSelectedVista__2IGJt:hover {
  background-color: #005f40 !important;
  color: #EDEDED !important;
  border: 1px solid #EDEDED !important;
}

/* Estilo para el botón activo */
.Home_mapSelectedVista__2IGJt.Home_active__YzwIj {
  background-color: white !important;
  color: #005f40 !important;
  font-weight: 600 !important;

}


.Home_mapSelect__hsQhY {
  border: none;
  font: 14px/16px Arial, Helvetica, sans-serif;
}

.Home_selectContainer__YEDcX {
  background: #005f40;
}

.Home_producerSelect__sLffh {
  margin-left: 50px;
  padding: 6px 8px;
  border-radius: 5px;
  font: 14px/16px Arial, Helvetica, sans-serif;
}

.Home_loginButton__uH8Eg {
  background: #005f40;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 2%;
  border-radius: 4px;
  color: #fff;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.Home_tableCropButton__sJfao {
  width: 140px;
  height: 45px;

  font-size: 11px;
  text-transform: uppercase;

  font-weight: 500;
  color: #ededed;
  background-color: #005f40;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.Home_tableCropButton__sJfao:hover {
  background-color: #005f3fa3;
  box-shadow: 0px 1px 2px #ededed;
  color: #ededed;
  transform: translate(1px);
}

.Home_leafletIcon__GlIIl {
  background: #005f40;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #005f40;
}

/* .emailColumn {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 2;
}

.emailHeader {
  position: sticky !important;
  left: 0;
  z-index: 5;

}

.tableContainer {
  overflow-x: auto;
} */


.Home_selectDisplay__kjLoz {
  padding: 8px 12px !important;
  font-size: .9rem !important;
  ;
  /* Tamaño por defecto */
}

/* Tamaño para pantallas pequeñas */
@media (max-width: 600px) {
  .Home_selectDisplay__kjLoz {
    font-size: 0.7rem !important;
    ;
  }
}

/* Tamaño para pantallas medianas */
@media (min-width: 600px) and (max-width: 1000px) {
  .Home_selectDisplay__kjLoz {
    font-size: 0.8rem !important;
    ;
  }
}

/* Tamaño para pantallas grandes */
@media (min-width: 1000px)and (max-width: 1500px) {
  .Home_selectDisplay__kjLoz {
    font-size: 0.85rem !important;
    ;
  }
}


/* Tabla contenedora con desplazamiento horizontal */
/* .tableContainer {
  width: 100%;
  overflow-x: auto;
} */

/* Encabezado de la tabla */
.Home_tableHeaderCell__VAQD7 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Muestra "..." para texto largo */
  position: relative;
  /* Necesario para el posicionamiento de la línea de redimensionamiento */
}

/* Contenedor del encabezado */
.Home_headerContainer__dbWZE {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Línea de redimensionamiento */
.Home_resizable__md5b5 {
  cursor: col-resize;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  background: #ccc;
  /* Línea visible para redimensionar */
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/*Legend specific*/
input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.legend {
  padding: 1px 6px;
  font: 11px Arial, Helvetica, sans-serif;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  line-height: 15px;
  color: #555;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  padding-top: 5px;
  padding-bottom: 5px;
}

.legend h4 {
  text-align: center;
  font-size: 11px;
  margin: 2px 12px 2px;
  color: #777;
}

.legend-error-map {
  display: flex;
  flex-direction: column;
  margin-right: 25%;
  margin-left: 25%;
  margin-bottom: 10%;
  text-align: center;
}

.legend-error-map h2 {
  color: #00540f;
}

.legend span {
  /* position: relative; */
  bottom: 3px;
  margin-left: 2px;
}

.legend div {
  width: 14px;
  height: 14px;
  float: left;
  margin: 0 3px 0 0;
  opacity: 0.7;
  border: 1px solid #777;
}

.loader h3 {
  color: #ededed;
  margin-left: 350px;
  margin-top: 100px;
}

.loader div {
  margin-left: 350px;
  border: 4px solid #ededed;
  border-radius: 50%;
  border-top: 4px solid #00540f;
  width: 100px;
  height: 100px;
  /* Safari */
  animation: spin 2s linear infinite;
}

.formCoord button {
  background-color: #005f40;
  border: #005f40;
  color: #ededed;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  height: 30px;
}

.formCoord {
  min-width: 200px;
}

.formCoord input {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border: none;
  height: 30px;
  padding-top: 4.5px;
  padding-left: 14px;
}

.swal-height {
  height: 55% !important;
  border: 1px solid #005f40 !important;
  border-bottom: 1px solid #005f40 !important;
}

.swal-height h5 {
  color: #005f40;
  margin-bottom: 1% !important;
  margin-top: 1% !important;
}

.swal-height-duplicate-name {
  height: 85% !important;
}

.swal-height-duplicate-name h5 {
  color: #005f40;
  margin-bottom: 1% !important;
  margin-top: 1% !important;
}

/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .loader h3 {
    margin-left: 150px;
    margin-top: 100px;
  }

  .legend {
    font-size: 10px;
  }

  .loader div {
    margin-left: 150px;
  }
}

.legend-error-map {
  width: 100%;
}

@media (min-width: 601px) and (max-width: 900px) {
  .loader h3 {
    margin-left: 200px;
    margin-top: 200px;
  }

  .loader div {
    margin-left: 200px;
  }

  .legend-error-map {
    width: 140%;
  }
}

@media (min-width: 901px) and (max-width: 1199px) {
  .legend-error-map {
    width: 120%;
  }
}

@media (min-width: 1200px) {
  .legend-error-map {
    width: 150%;
  }
}

.swal2-container {
  z-index: 11000 !important;
}

.leaflet-right .leaflet-control {
  margin-right: 5px !important;
}

.leaflet-top .leaflet-control {
  margin-top: 5px !important;
}

.leaflet-control-layers-expanded {
  font: 11px Arial, Helvetica, sans-serif;
  color: #555;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 3px;
}

.custom-error-icon {
  font-size: 0.7rem;
  /* Adjust the size as needed */
}

.custom-title h5 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #005f40;
  margin-bottom: 1%;
  margin-top: 1%;
}

.leaflet-control-attribution {
  font-size: 9px !important;
}





/* 
table {
  border-collapse: collapse;

  width: 100%;

} */

.table-container {
  width: 100%;
  /* max-height: 700px; */
  max-height: calc(100vh - 150px);
  /* Limita la altura a un porcentaje de la ventana */
  min-height: 200px;
  /* Asegura que siempre tenga una altura mínima visible */
  /* Define la altura máxima para la tabla */
  overflow-y: auto;
  /* Agrega scroll vertical */
  overflow-x: auto;
  /* Agrega scroll horizontal si es necesario */
  position: relative;
  display: flex;

}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

/* MANTIENE EL HEADER STATIC */
.table-container th {
  position: sticky;
  /* Fija el encabezado */
  top: 0;
  /* Alinea el encabezado al inicio */
  z-index: 1;
  /* Asegúrate de que el encabezado esté por encima del contenido */
  background-color: #005f40;
  /* Fondo para el encabezado */
  color: #e3e0e0;
  /* Color del texto del encabezado */
  text-align: left;

}



.table-container th::after {
  content: "";
  position: absolute;
  top: 50%;
  /* Posicionar en el centro vertical */
  right: 0;
  /* Colocar al final de la celda */
  transform: translateY(-50%);
  /* Ajustar al centro exacto */
  height: 50%;
  /* Ajustar la altura de la línea */
  width: 1px;
  /* Grosor de la línea */
  background-color: #cccccc3c;
  /* Color de la línea */
}

.table-container th:last-child::after {
  display: none;
  /* Eliminar línea en la última columna */
}

.table-container td {
  text-align: left;
  padding: 8px;

}

.table-container th,
.table-container td {
  width: 150px;
}

table,
.divTable {
  width: -moz-fit-content;
  width: fit-content;
}

.tr {
  display: flex;
}

tr,
.tr {
  width: -moz-fit-content;
  width: fit-content;
  height: 30px;
}

th,
.th,
td,
.td {
  box-shadow: inset 0px 1px 0px 0px lightgray;
  padding: 0.25rem;
}

th,
.th {
  /* padding: 2px 4px; */

  position: relative;
  font-weight: bold;
  text-align: center;
  height: 30px;
}

td,
.td {
  height: 30px;
}

.resizer {
  position: absolute;
  top: 0;
  height: 100%;
  width: 3px;
  background: #cccccc3c;
  cursor: col-resize;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: none;
}

.resizer.ltr {
  right: 0;
}

.resizer.rtl {
  left: 0;
}

.resizer.isResizing {
  background: #e3e0e0;
  opacity: 1;
}

.header {
  background-color: #005f40;
  color: #e3e0e0;
  font-size: 14px;
  text-transform: capitalize;

}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover>.resizer {
    opacity: 1;
  }
}

/* table {

  border-collapse: separate !important;
  border-spacing: 0;
} */

table {
  border-collapse: collapse;
  table-layout: fixed;
  /* Asegura que las columnas se mantengan alineadas */
  width: 100%;
}


.leaflet-draw-actions a.disabled {
  pointer-events: none;
  /* Deshabilita clics */
  opacity: 0.7;
  /* Apariencia de deshabilitado */
  cursor: not-allowed;
  /* Cambia el cursor */
}


.my-custom-tooltip {
  /* Fondo semitransparente */
  background-color: rgba(255, 255, 255, 0.8) !important;

  /* Centrar el texto */
  text-align: center;
  font-weight: bold;


  /* Opcional: bordes, color de texto, etc. */
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;

  /* Ajusta el padding a tu gusto */
  padding: 5px;
}

.my-confirm-button:focus,
.my-confirm-button:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}



/* Efecto para el overlay del modal (fondo semitransparente) */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  /* Comenzamos invisible */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-overlay.open {
  /* Cuando está "open", se ve */
  opacity: 1;
  visibility: visible;
}

/* Contenedor interno del modal */
.modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;

  /* También podemos animar la escala o la opacidad */
  transform: scale(0.9);
  opacity: 0;
  transition: transform 0.4s ease, opacity 0.4s ease;
  max-width: 80vw;
  max-height: 80vh;
}

.modal-content.open {
  transform: scale(1);
  opacity: 1;
}

/* Imagen con fade-in al cargarse */
.fade-in-image {
  opacity: 0;
  transition: opacity 0.5s ease;
  max-width: 100%;
  max-height: 70vh;
  -o-object-fit: contain;
     object-fit: contain;
}

.fade-in-image.loaded {
  opacity: 1;
}
